import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ImageUploading from "react-images-uploading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	errorMessage: {
		color: "red",
		marginTop: theme.spacing(2),
	},
}));

const BannerUploader = ({
	handleImageUpload,
	image,
	setImageURL,
	handleDeleteImg,
	errorMes,
}) => {
	const classes = useStyles();
	const [images, setImages] = useState([]);
	const [uploading, setUploading] = useState(false);

	const maxNumber = 1; // Максимальное количество изображений
	const maxFileSize = 512000; // Максимальный размер файла 500 KB
	const maxAcceptType = ["jpg", "jpeg", "png"];

	useEffect(() => {
		if (image) {
			setImages([{ dataURL: image }]);
		}
	}, [image]);

	const handleUpload = async (imageList) => {
		if (imageList.length > 0) {
			const file = imageList[0].file;
			if (file) {
				setUploading(true);
				try {
					await handleImageUpload(file);
					// Здесь можно передать URL в родительский компонент, если нужно
				} catch (error) {
					console.error("Error uploading image:", error);
				} finally {
					setUploading(false);
				}
			}
		}
	};

	const handleDelete = async (img) => {
		// Вызываем функцию удаления изображения из родительского компонента
		await handleDeleteImg(img);
		setImageURL("");
		// Очищаем текущее изображение в BannerUploader
		setImages([]);
	};

	return (
		<div>
			<Typography variant="h6">Загрузка баннера</Typography>

			<ImageUploading
				multiple={false}
				value={images}
				onChange={(imageList) => {
					setImages(imageList);
					handleUpload(imageList); // Передаем выбранные изображения в родительский компонент
				}}
				maxNumber={maxNumber}
				maxFileSize={maxFileSize}
				acceptType={maxAcceptType}
			>
				{({
					imageList,
					onImageUpload,
					onImageRemove,
					isDragging,
					dragProps,
				}) => (
					<div>
						<Button
							onClick={onImageUpload}
							style={isDragging ? { color: "red" } : null}
							{...dragProps}
						>
							Загрузить изображение
						</Button>
						{imageList.length > 0 && (
							<div style={{ marginTop: 20 }}>
								{imageList.map((image, index) => (
									<div key={index} style={{ marginBottom: 10 }}>
										<img src={image.dataURL} alt="" width="100" />
										<Button
											variant="contained"
											color="secondary"
											onClick={() => handleDelete(image.dataURL)}
											style={{ marginLeft: 10 }}
										>
											Удалить
										</Button>
									</div>
								))}
							</div>
						)}
					</div>
				)}
			</ImageUploading>

			{errorMes && (
				<Typography className={classes.errorMessage}>{errorMes}</Typography>
			)}

			{images.length > 0 && (
				<Chip label="Изображение готово к загрузке" style={{ marginTop: 20 }} />
			)}
		</div>
	);
};

export default BannerUploader;
