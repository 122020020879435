import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { withFirebase, storage } from "../../firebase";
import Labels from ".";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import { ref } from "firebase/storage";

const LabelsContainer = ({ firebase }) => {
	const [preloader, setPreloader] = useState(false);
	const [labels, setLabels] = useState(null);
	const [keyShop, setKeyShop] = useState(null);
	const [currentCity, setCurrentCity] = useState(null);
	const [currentBrand, setCurrentBrand] = useState(null);

	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");

	const location = useLocation();

	useEffect(() => {
		setKeyShop(location.pathname);
		getFieldsLabels();
	}, [location.pathname]); // Обновляем данные при изменении пути

	const getFieldsLabels = () => {
		let urlParts = location.pathname.split("/");
		let url = urlParts.find((item) => item === "0" || parseInt(item));

		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags/tags`
			)
			.on("value", (snapshot) => {
				const labelsData = snapshot.val();
				setLabels(labelsData);
				setKeyShop(url);
				setCurrentCity(currentCity);
				setCurrentBrand(currentBrand);
			});
	};

	const changeChecker = useCallback(
		(value, id) => {
			console.log("id (changeChecker) --- >", id);
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			setPreloader(true);
			firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags/tags/${id}`
				)
				.update({ isAvailable: value })
				.then(() => {
					setPreloader(false);
				});
		},
		[currentCity, currentBrand, firebase.bd]
	);

	const deleteLabel = useCallback(
		(id) => {
			console.log("=== Delete label ===", id);
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			setPreloader(true);

			let img = labels.find((item, index) => {
				return index === +id;
			});

			const updatedLabels = labels.filter((_, index) => index !== +id);

			setLabels(updatedLabels);

			firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags/tags`
				)
				.set(updatedLabels)
				.then(() => {
					if (img) {
						getFieldsLabels();
						handleDeleteImg(img.image);
					}
					setPreloader(false);
					getFieldsLabels();
				})
				.catch((error) => {
					console.error("Error deleting label:", error);
					setPreloader(false);
				});
		},

		[currentCity, currentBrand, firebase.bd, getFieldsLabels]
	);

	const createLabel = useCallback((data) => {
		console.log("=== Create label data ===", data);
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		setPreloader(true);
		firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags/tags`
			)
			.once("value")
			.then((snapshot) => {
				const existingTags = snapshot.val() || [];
				// Убедитесь, что в массиве нет `undefined` элементов
				const filteredTags = existingTags.filter((tag) => tag !== undefined);
				// Добавляем новый тег в конец массива
				const updatedTags = [...filteredTags, data];

				firebase.bd
					.ref(
						`${
							isDebug ? "debug" : ""
						}/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags/tags`
					)
					.set(updatedTags)
					.then(() => {
						setPreloader(false);
						// window.location.reload();
						getFieldsLabels();
					})
					.catch((error) => {
						console.error("Error adding label:", error);
						setPreloader(false);
					});
			})
			.catch((error) => {
				console.error("Error fetching tags:", error);
				setPreloader(false);
			});
	});

	const updateLabel = useCallback(
		(labelId, updatedData) => {
			console.log("=== Update label data ===", labelId, updatedData);
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			setPreloader(true);

			// Обновляем данные для конкретного лейбла по ключу
			firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/productsTags/tags/${labelId}`
				)
				.update(updatedData)
				.then(() => {
					setPreloader(false);
					getFieldsLabels(); // Перезагружаем данные, чтобы отобразить изменения
				})
				.catch((error) => {
					console.error("Error updating label:", error);
					setPreloader(false);
				});
		},
		[currentCity, currentBrand, firebase.bd, getFieldsLabels]
	);

	const uploadImage = (file) => {
		return new Promise((resolve, reject) => {
			const uniqueFileName = `${uuidv4()}_${file.name}`;

			const uploadTask = firebase.storage.ref(`${uniqueFileName}`).put(file);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Опционально можно отслеживать процесс загрузки
					let progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
				},
				(error) => {
					// Обработка ошибок
					console.error("Upload failed:", error);
					reject(error); // Отклонение промиса при ошибке
				},
				() => {
					// Обработка успешной загрузки
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						console.log("File available at", downloadURL);
						resolve(downloadURL); // Возвращение downloadURL через промис
					});
				}
			);
		});
	};

	const handleDeleteImg = async (img) => {
		const labelIndex = labels.findIndex((item) => item.image === img);

		let data;
		if (labelIndex !== -1) {
			data = {
				...labels[labelIndex],
				image: "",
			};
		}

		const fileName = img.substring(img.lastIndexOf("/") + 1, img.indexOf("?"));

		let desertRef = firebase.storage.ref(`${fileName}`);

		desertRef
			.delete()
			.then(() => {})
			.catch((error) => {
				console.log(error);
			});
	};

	const deleteBanner = () => {};

	return (
		<div>
			<Labels
				updateLabel={updateLabel}
				loadStatus={preloader}
				labels={labels}
				changeChecker={changeChecker}
				createLabel={createLabel}
				deleteLabel={deleteLabel}
				uploadImage={uploadImage}
				handleDeleteImg={handleDeleteImg}
				deleteBanner={deleteBanner}
			/>
		</div>
	);
};

export default withFirebase(LabelsContainer);
